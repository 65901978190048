@import '../../utils/variables';

.SocialLinksContainer {
  position: fixed;
  padding-left: 10px;
  margin-top: 20vh;
  z-index: 50;
  @media screen and (max-width: $medium) {
    position: relative;
    display: flex;
    margin-top: 0;
    padding: 0;
  }
  a {
    display: block;
    padding: 25px;
  }
  .socialLinks {
    height: 30px;
  }
  .link {
    padding: 20px;
    margin: 10px;
    box-sizing: border-box;   
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    transition: transform 0.3s, box-shadow 0.5s;
    &:hover {
      img {
        transform: scale(1.1)
      }
      transform: scale(1.05)
    }
    img {
      margin: auto;
      object-fit: cover;
      transition: transform 0.3s;
    }
  }
}
