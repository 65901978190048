@import '../../../utils/variables';

.componentWrapper {
  margin-top: 100px;
  @media (max-width: $medium) {
    margin-top: 0;
  }
  .blurb {
    padding-left: 100px;
    @media (max-width: $medium) {
      padding-left: 0;
      width: 75vw;
      text-align: justify;
      margin: auto;
      overflow: scroll;
    }
  }
  .gridWrapper {
    display: grid;
    grid-template-columns: auto 9fr;
    grid-template-rows: repeat(2, 1fr);
    @media (max-width: $medium) {
      display: block;
    }
    .one {
      grid-row: 1 / 4;
      @media (max-width: $medium) {
        margin: auto;
        text-align: center;
      }
    }
    .two {
      grid-row: 1;
      padding-left: 10px;
      display: flex;
      align-items: center;
      border-bottom: 4px solid white;
      @media (max-width: $medium) {
        display: block;
        padding-left: 0;
        padding-bottom: 10px;
        text-align: center;
      }
    }
    .three {
      grid-row: 2;
      padding-left: 10px;
      display: flex;
      align-items: center;
      @media (max-width: $medium) {
        display: block;
        padding-left: 0;
        padding-bottom: 10px;
        text-align: center;
      }
      img {
        padding-right: 10px;
        @media (max-width: $medium) {
          visibility: hidden;
        }
      }
    }
  }
}
