@import '../../../utils/variables';

.container {
  width: 350px;
  height: 350px;
  overflow: hidden;
  @media (max-width: $medium) {
    width: 80vw;
    height: 80vw;
  }
  &:hover {
    .mainContent {
      opacity: 1;
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
  .mainContent {
    opacity: 0;
    display: flex;
    height: 350px;
    padding: 10px;
    text-align:center;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    &:hover {
      background-color: rgba(black, 0.7);
    }
    img {
      width: 60px;
      opacity: 0;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
