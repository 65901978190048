@import '../../utils/variables';

.TopNavContainer {
  width: 80%;
  margin: auto;
  height: 10%;
  border-bottom: 1px solid white;
}

.TopNavRight {
  @media (max-width: $medium) {
      display: none;
  }
  a {
    text-decoration: none;
    font-size: 20px;
    color: white;
    margin-right: 5vw;
    height: 100px;
    line-height: 100px;
    white-space: nowrap;
  }
  a:hover {
    border-bottom: 2px solid rgba($color: #BECC0C, $alpha: 1);
  }
}
