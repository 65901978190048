@import './utils/variables';

.App {
  background-color: rgba($color: #080935, $alpha: 1);
  color: white;
  height: 100vh;
  overflow: hidden;
  font-family: $site-font;
  font-weight: bold;
  .left {
    width: 10vw;
  }
  .container {
    @media (max-width: $medium) {
      max-height: 77vh;
      overflow: scroll;
    }
    position: relative;
    margin: auto;
  }
  .dotGroup {
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 20vh;
    button {
      padding: 5px;
      margin: 10px;
      border: 2px solid white;
      border-radius: 50%;
      background-color: rgba($color: #080935, $alpha: 1);
      &:disabled {
        background-color: white;
      }
    }
    @media (max-width: $medium) {
      bottom: 3vh;
    }
  }
  .buttonBack {
    position: absolute;
    top: 50%;
    left: 5vw;
    transform: translateY(-50%);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color:rgba($color: #080935, $alpha: 0);
    @media (max-width: $medium) {
      left: 0vw;
    }
  }
  .buttonNext {
    position: absolute;
    top: 50%;
    right: 5vw;
    transform: translateY(-50%);
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color:rgba($color: #080935, $alpha: 0);
    border: none;
    @media (max-width: $medium) {
      right: 0vw;
    }
  }
  .arrow {
    border: solid white;
    width: 20px;
    height: 20px;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .right {
    @extend .arrow;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: transform 0.3s, box-shadow 0.5s;
    &:hover {
      transform: rotate(-45deg) scale(1.3);
    }
  }
  .left {
    @extend .arrow;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transition: transform 0.3s, box-shadow 0.5s;
    &:hover {
      transform: rotate(135deg) scale(1.3);
    }
  }
  .slide {
    // no css
  }
}
