@import '../../utils/variables';

.technologyContainer {
  display: flex;
  width: 80%;
  margin: auto;
  align-content: center;
  justify-content: space-evenly;
  padding-top: 40vh;
  @media (max-width: $medium) {
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    padding-top: 0vh;
    width: 75vw;
    margin: auto;
  }
  img {
    height: 100px;
  }
  .skillContainer {
    margin-left: 40px;
    margin-bottom: 5%;
    font-size: 20px;
    justify-content: space-around;
    padding-left: 60px;
    border-left: 3px solid white;
    @media (max-width: $medium) {
      padding-left: 0;
      padding-top: 5%;
      margin-left: 0;
      border-left: 0;
      border-top: 3px solid white;
    }
    h3 {
      padding: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
