@import '../../utils/variables';

.projectsComponentContainer {
  min-height: 100%;
  height: 100%;
  width: 80%;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 35vh;
  @media (max-width: $medium) {
    margin-top: 0;
    width: 80vw;
    overflow: scroll;
  }
  .projectIconContainer {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    @media (max-width: $medium) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
    }
  }
}
