@import '../../utils/variables';

.aboutContainer {
  width: 80%;
  display: flex;
  margin: auto;
  padding-top: 30vh;
  @media (max-width: $medium) {
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    padding-top: 0vh;
    width: 75vw;
    overflow: scroll;
  }
  .stephPhoto {
    width: 100%;
    height: 100%;
  }
  .contentContainer {
    text-align: justify;
    padding: 20px;
    @media (max-width: $medium) {
      width: 75vw;
      padding: 0;
      background-color: rgba(gray, 0);
    }
  }
  p {
    font-size: 20px;
    @media (max-width: $medium) {
      font-size: 15px;
    }
  }
}
