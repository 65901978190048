@import '../../utils/variables';

.experienceComponentContainer {
  min-height: 100%;
  height: 100%;
  width: 80vw;
  margin: auto;
  padding-top: 15vh;
  @media (max-width: $medium) {
    padding-top: 0vh;
    overflow: scroll;
  }
  h5 {
    font-size: 50px;
  }
}

